import React, { useEffect, useState } from 'react';
import './LocationSelectContent.css';

const LocationSelectContent = (prop) => {
  const [isLoading, setIsLoading] = useState(false); // 위치 정보 로딩 상태 관리
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    // 컴포넌트 마운트 시 body의 display 스타일을 변경
    const originalDisplayStyle = document.body.style.display; // 원래의 display 스타일 저장

    if (isMobile == false) {
      document.body.style.display = 'block'; // body의 display를 block으로 설정
    }

    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_MAP_KEY}&autoload=false&libraries=services`;
    document.head.appendChild(script);

    script.onload = () => {
      setIsLoading(true); // 위치 정보 로딩 시작

      window.kakao.maps.load(() => {
        var existLocation = localStorage.getItem('lunch_lng') && localStorage.getItem('lunch_lat');
        // 이미 설정한 위치정보가 존재하면
        if (existLocation && isMobile == false) {
          initializeMap(
            new window.kakao.maps.LatLng(localStorage.getItem('lunch_lat'), localStorage.getItem('lunch_lng')), 
            false
          );
          setIsLoading(false); // 위치 정보 로딩 종료
        } else {
          // 사용자의 현재 위치를 가져오거나, 허용하지 않았을 경우 기본 위치를 설정합니다.
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setIsLoading(false); // 위치 정보 로딩 종료
              initializeMap(
                new window.kakao.maps.LatLng(position.coords.latitude, position.coords.longitude), 
                true
              );
            }, 
            (error) => {
              setIsLoading(false); // 위치 정보 로딩 종료
              console.error("Geolocation access denied: ", error);
              initializeMap(
                new window.kakao.maps.LatLng(37.49203, 126.9242), 
                true
              );
            }, 
            {
              enableHighAccuracy: true
            }
          );
        }
      });
      
    };

    // 주소 검색 팝업을 위한 스크립트 로딩
    const postcodeScript = document.createElement('script');
    postcodeScript.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    document.head.appendChild(postcodeScript);

    return () => {
      document.body.style.display = originalDisplayStyle; // 컴포넌트 언마운트 시 body 스타일 원래대로 복원
      document.head.removeChild(script);
      window.removeEventListener('resize', handleResize);
      // 컴포넌트 언마운트 시 스크립트 제거
      document.head.removeChild(postcodeScript);
    };
  }, []);

  const openPostcodePopup = () => {
    new window.daum.Postcode({
      oncomplete: function(data) {
        // 번지정보가 없는 케이스가 있어서 도로명까지만
        document.getElementById("keyword").value = extractRoad(data.address); // 주소 넣기
      }
    }).open();
  };

  const extractRoad = (address) => {
    // '길'과 '로'의 인덱스를 찾음
    const roadIndex = address.indexOf('길') + 1;
    const streetIndex = address.indexOf('로') + 1;
    
    // '길' 또는 '로' 중 더 뒤에 오는 인덱스를 사용하여 문자열을 잘라냄
    const endIndex = Math.max(roadIndex, streetIndex);
    
    // endIndex가 0보다 크면 해당 인덱스까지 문자열을 반환, 아니면 원래 주소 반환
    return endIndex > 0 ? address.substring(0, endIndex) : address;
  }

  const isInApp = () => {
    return /InOJMApp/i.test(navigator.userAgent);
  }
  
  useEffect(() => {
      const keywordInput = document.getElementById('keyword');
      if (isInApp() == false) {
        keywordInput.addEventListener('click', openPostcodePopup);
      }
  
      return () => {
        if (isInApp() == false) {
        keywordInput.removeEventListener('click', openPostcodePopup);
        }
      };
  }, []);
  

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') { // 엔터 키가 눌렸을 때
      document.querySelector('#searchBtn').click();
    }
  };

  function initializeMap(center, isFirst) {
    const mapContainer = document.getElementById('map');
    const mapOption = {
      center: center,
      level: 3,
    };
    const map = new window.kakao.maps.Map(mapContainer, mapOption);
    let marker = new window.kakao.maps.Marker(), // 클릭한 위치를 표시할 마커입니다
    infowindow = new window.kakao.maps.InfoWindow({zindex:1}); // 클릭한 위치에 대한 주소를 표시할 인포윈도우입니다

    // 검색 기능
    document.getElementById('searchBtn').addEventListener('click', function() {
      const keyword = document.getElementById('keyword').value;
      searchPlaces(keyword, {
        map: map, 
        marker: marker,
        infowindow: infowindow
      });
    });

    marker.setPosition(center);
    marker.setMap(map);

    var mapSettingInfos = {
      map: map,
      infowindow: infowindow,
      marker: marker,
      latlng: center
    }

    if (isFirst) {
      setSelectAddrInfowindow(mapSettingInfos, "정확한 현재위치로 좌표를 이동시켜주세요.");
    } else {
      setSelectAddrInfowindow(mapSettingInfos);
    }

    window.kakao.maps.event.addListener(map, 'click', function(mouseEvent) {
      const latlng = mouseEvent.latLng;

      marker.setPosition(latlng);
      marker.setMap(map);

      // todo 로컬스토리지에 좌표저장을 해도되는지 체크 필요.
      localStorage.setItem('lunch_lng', latlng.getLng());
      localStorage.setItem('lunch_lat', latlng.getLat());

      setSelectAddrInfowindow({
        map: map,
        infowindow: infowindow,
        marker: marker,
        latlng: latlng
      });
    });
  }

  function setSelectAddrInfowindow(mapSettingInfos, marketDesc) {
    const geocoder = new window.kakao.maps.services.Geocoder();

    geocoder.coord2Address(mapSettingInfos.latlng.La, mapSettingInfos.latlng.Ma, function(result, status) {
      if (status === window.kakao.maps.services.Status.OK) {
        var detailAddr = !!result[0].road_address ? '<div>도로명주소 : ' + result[0].road_address.address_name + '</div>' : '';
        detailAddr += '<div>지번 주소 : ' + result[0].address.address_name + '</div>';

        var selectAddr = `${result[0].address.region_1depth_name} ${result[0].address.region_2depth_name} ${result[0].address.region_3depth_name}`

        prop.setSelectAddrFunc(selectAddr)

        console.log(marketDesc)

        var content;

        if (typeof(marketDesc) != 'undefined') {
          content = '<div class="bAddr">' +
                        '<span class="title">' + marketDesc + '</span>' +
                    '</div>';
        } else {
          content = '<div class="bAddr">' +
                          '<span class="title">선택된 주소</span>' +
                          detailAddr +
                    '</div>';
        }

        mapSettingInfos.infowindow.setContent(content);
        mapSettingInfos.infowindow.open(mapSettingInfos.map, mapSettingInfos.marker);
      }
    });
  }

  function searchPlaces(keyword, mapSettingInfos) {
    if (!keyword.replace(/^\s+|\s+$/g, '')) {
      alert('키워드를 입력해주세요!');
      return false;
    }

    const ps = new window.kakao.maps.services.Places();

    ps.keywordSearch(keyword, function(data, status) {
      if (status === window.kakao.maps.services.Status.OK) {
        const bounds = new window.kakao.maps.LatLngBounds();


        for (let i = 0; i < data.length; i++) {
          bounds.extend(new window.kakao.maps.LatLng(data[i].y, data[i].x));
        }

        let latlng = new window.kakao.maps.LatLng(data[0].y, data[0].x);

        mapSettingInfos.marker.setPosition(latlng);
        mapSettingInfos.marker.setMap(mapSettingInfos.map);

        setSelectAddrInfowindow({
          map: mapSettingInfos.map,
          infowindow: mapSettingInfos.infowindow,
          marker: mapSettingInfos.marker,
          latlng: latlng
        }, "정확한 현재위치로 좌표를 이동시켜주세요.");

        mapSettingInfos.map.setBounds(bounds);
      } else if (status === window.kakao.maps.services.Status.ZERO_RESULT) {
        alert('검색 결과가 존재하지 않습니다.');
      } else if (status === window.kakao.maps.services.Status.ERROR) {
        alert('검색 결과 중 오류가 발생했습니다.');
      }
    });
  }

  return (
    <div className="map_wrap">
      <input id="keyword" type="text" placeholder="동을 입력하세요" onKeyDown={handleKeyDown} />
      <button id="searchBtn" class="btn btn-outline-success" style={{zoom:0.85}}>이동</button>
      {isLoading && <div className="loading-message">위치 정보를 불러오는 중입니다...</div>}
      <div id="map" style={{ width: '100%', height: '350px' }}></div>
    </div>
  );
};

export default LocationSelectContent;

