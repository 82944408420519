import React, { useState } from 'react';


function MenuTypeContent({ onSelectMenuType }) {
    const [selectedMenuType, setSelectedMenuType] = useState(null); 

    const menuTypes = {
      all: { label: 'ALL', img: '/food/all.png' },
      korean: { label: '한식', img: '/food/한식.png' },
      japanese: { label: '일식', img: '/food/일식.png' },
      chinese: { label: '중식', img: '/food/중식.png' },
      western: { label: '양식', img: '/food/양식.png' },
      etc: { label: '기타', img: null }
  };
  
    const handleSelectMenuType = (key) => {
        onSelectMenuType(key);
        setSelectedMenuType(key); 
    }

    return (
      <div id='btn-box'>
          {Object.entries(menuTypes).map(([key, {label, img}]) => (
              <button
                  key={key} 
                  onClick={() => handleSelectMenuType(key)} 
                  className="type_btn m-1"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', overflow: 'hidden', borderRadius: '10px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
                  {img && <img src={img} alt={label} style={{ width: '100px', height: '100px', borderRadius: '5px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)' }} />}
                  <span style={{ marginLeft: '10px', color: '#fff', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}>{label}</span>
              </button>
          ))}
      </div>
  );
}

export default MenuTypeContent;
