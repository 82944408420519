// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {font-weight:bold;display:block;}
.hAddr {position:absolute;left:10px;top:10px;border-radius: 2px;background:#fff;background:rgba(255,255,255,0.8);z-index:1;padding:5px;}
#centerAddr {display:block;margin-top:2px;font-weight: normal;}
.bAddr {padding:5px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.map_wrap > #keyword, #searchBtn { zoom : 1.5}
.map_wrap {
    height: '350px';
    width: '100%';
    flex: '1' 1;
}
.loading-message {
    position: fixed; /* 뷰포트에 상대적으로 위치 */
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.25rem; /* 글자 크기 조정 */
    color: #4A90E2; /* 글자 색상을 밝은 파란색으로 변경 */
    background-color: #FFFFFF; /* 배경 색상을 흰색으로 변경 */
    padding: 20px 40px; /* 내부 여백 조정 */
    border-radius: 5px; /* 모서리 둥글기 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 상자 그림자 추가 */
    border: 1px solid #DDD; /* 테두리 추가 */
    z-index: 1000; /* 다른 요소 위에 확실히 표시 */
    display: flex;
    align-items: center;
    justify-content: center;
    zoom: 3;
    z-index: -1;
  }
  `, "",{"version":3,"sources":["webpack://./src/LocationSelectContent.css"],"names":[],"mappings":"AAAA,QAAQ,gBAAgB,CAAC,aAAa,CAAC;AACvC,QAAQ,iBAAiB,CAAC,SAAS,CAAC,QAAQ,CAAC,kBAAkB,CAAC,eAAe,CAAC,gCAAgC,CAAC,SAAS,CAAC,WAAW,CAAC;AACvI,aAAa,aAAa,CAAC,cAAc,CAAC,mBAAmB,CAAC;AAC9D,QAAQ,WAAW,CAAC,uBAAuB,CAAC,gBAAgB,CAAC,mBAAmB,CAAC;AACjF,mCAAmC,UAAU;AAC7C;IACI,eAAe;IACf,aAAa;IACb,WAAS;AACb;AACA;IACI,eAAe,EAAE,kBAAkB;IACnC,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,kBAAkB,EAAE,aAAa;IACjC,cAAc,EAAE,uBAAuB;IACvC,yBAAyB,EAAE,mBAAmB;IAC9C,kBAAkB,EAAE,aAAa;IACjC,kBAAkB,EAAE,YAAY;IAChC,wCAAwC,EAAE,cAAc;IACxD,sBAAsB,EAAE,WAAW;IACnC,aAAa,EAAE,oBAAoB;IACnC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,OAAO;IACP,WAAW;EACb","sourcesContent":[".title {font-weight:bold;display:block;}\n.hAddr {position:absolute;left:10px;top:10px;border-radius: 2px;background:#fff;background:rgba(255,255,255,0.8);z-index:1;padding:5px;}\n#centerAddr {display:block;margin-top:2px;font-weight: normal;}\n.bAddr {padding:5px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}\n.map_wrap > #keyword, #searchBtn { zoom : 1.5}\n.map_wrap {\n    height: '350px';\n    width: '100%';\n    flex: '1';\n}\n.loading-message {\n    position: fixed; /* 뷰포트에 상대적으로 위치 */\n    top: 25%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 1.25rem; /* 글자 크기 조정 */\n    color: #4A90E2; /* 글자 색상을 밝은 파란색으로 변경 */\n    background-color: #FFFFFF; /* 배경 색상을 흰색으로 변경 */\n    padding: 20px 40px; /* 내부 여백 조정 */\n    border-radius: 5px; /* 모서리 둥글기 */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 상자 그림자 추가 */\n    border: 1px solid #DDD; /* 테두리 추가 */\n    z-index: 1000; /* 다른 요소 위에 확실히 표시 */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    zoom: 3;\n    z-index: -1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
