import React, { useState, useEffect } from 'react';
import MenuTypeContent from './MenuTypeContent';
import RouletteContent from './RouletteContent';
import LocationSelectContent from './LocationSelectContent';
import ResultContent from './ResultContent';
import './App.css'

function Step({ stepNumber, description, detailDescription, onButtonClick, buttonLabel, containerId, children }) {
  return (
    <div id={containerId}>
      <h2>{stepNumber}단계) {description}</h2>
      <p>{detailDescription}</p>
      {children}
      {/* onButtonClick이 정의되어 있을 때만 버튼을 렌더링 */}
      {onButtonClick && (
        <button onClick={onButtonClick} className="btn btn-primary">{buttonLabel}</button>
      )}
    </div>
  );
}

function App() {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedMenuType, setSelectedMenuType] = useState('');
  const [currentMenu, setCurrentMenu] = useState(null)
  const [currentAddr, setCurrentAddr] = useState(null)

  const nextStep = () => setCurrentStep(currentStep + 1);
  const restart = () => {
    setCurrentStep(1);
    setSelectedMenuType(''); // 다시 시작할 때 메뉴 선택도 초기화
  }

  // 메뉴 종류 선택 처리
  const handleSelectMenuType = (type) => {
    setSelectedMenuType(type);
    nextStep(); // 메뉴를 선택하면 자동으로 다음 단계로 이동
  };

  var resultDetail = `"${currentAddr}" 의 "${currentMenu}" 메뉴를 판매하는 가게 리스트입니다.`;

  return (
    <div>
      {currentStep === 1 && (
        <Step stepNumber="1" description="메뉴 종류 선택" detailDescription="★☆★☆원하는 메뉴 타입을 선택하세요.★☆★☆" containerId={"main-container"}>
          <MenuTypeContent onSelectMenuType={handleSelectMenuType} />
        </Step>
      )}
      {currentStep === 2 && (
        <Step stepNumber="2" description="점심 룰렛" detailDescription="아래의 룰렛이 당신의 점심메뉴 선택고민을 해결해줄겁니다." containerId={"main-container"}>
          <RouletteContent selectedMenuType={selectedMenuType} setMenuFunc={(menu) => { setCurrentMenu(menu); nextStep();}} />
        </Step>
      )}
      {currentStep === 3 && (
        <Step stepNumber="3" description="현재 사용자 위치 선택" detailDescription="★☆브라우저의 위치정보는 정확하지 않아서 현재 위치를 마커로 표시해주세요~★☆" onButtonClick={() => { if (currentAddr == null) { alert("현재 위치를 골라주세요."); return; } nextStep();}} buttonLabel="다음 단계" containerId={"map-container"}>
          <LocationSelectContent setSelectAddrFunc={(addr) => {setCurrentAddr(addr)}}/>
        </Step>
      )}
      {currentStep === 4 && (
        <Step stepNumber="4" description="결과페이지" detailDescription={resultDetail} onButtonClick={restart} buttonLabel="다시하기" containerId={"map-container"}>
          <ResultContent menu={currentMenu} addr={currentAddr} />
        </Step>
      )}
    </div>
  );
}


export default App;
