import React, { useState, useEffect } from 'react'
import nextStep from './App'
import { Wheel } from 'react-custom-roulette'

const img_size=0.6

const koreanData = [
  { 
    option: '동태탕', 
    style: { backgroundColor: 'darkslategrey' },
    image: 
    {
      uri: '/food/동태탕.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '냉면', 
    style: { backgroundColor: 'fuchsia' },
    image: 
    {
      uri: '/food/냉면.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '김밥', 
    style: { backgroundColor: 'green' },
    image: 
    {
      uri: '/food/김밥.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '떡볶이', 
    style: { backgroundColor: 'teal' },
    image: 
    {
      uri: '/food/떡볶이.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '감자탕', 
    style: { backgroundColor: 'purple' },
    image: 
    {
      uri: '/food/감자탕.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '카레',  
    style: { backgroundColor: 'green', textColor: 'black'},
    image: 
    {
      uri: '/food/카레.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '돈까스', 
    style: { backgroundColor: 'azure' },
    image: 
    {
      uri: '/food/돈까스.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '불고기', 
    style: { backgroundColor: 'beige' },
    image: 
    {
      uri: '/food/불고기.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '비빔밥', 
    style: { backgroundColor: 'bisque' },
    image: 
    {
      uri: '/food/비빔밥.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '삼계탕', 
    style: { backgroundColor: 'blanchedalmond' },
    image: 
    {
      uri: '/food/삼계탕.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '순두부찌개',
    style: { backgroundColor: 'blueviolet' }, 
    image: 
    {
      uri: '/food/순두부찌개.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '된장찌개', 
    style: { backgroundColor: 'brown' },
    image: 
    {
      uri: '/food/된장찌개.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '보쌈', 
    style: { backgroundColor: 'burlywood' },
    image: 
    {
      uri: '/food/보쌈.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '추어탕', 
    style: { backgroundColor: 'cadetblue' },
    image: 
    {
      uri: '/food/추어탕.png', 
      sizeMultiplier:img_size
    } 
  },
];

const japaneseData = [
  { 
    option: '라멘', 
    style: { backgroundColor: 'white' },
    image: 
    {
      uri: '/food/ramen.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '초밥', 
    style: { backgroundColor: 'cornflowerblue' },
    image: 
    {
      uri: '/food/초밥.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '카레',  
    style: { backgroundColor: 'green', textColor: 'black'},
    image: 
    {
      uri: '/food/카레.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '돈까스', 
    style: { backgroundColor: 'chartreuse' },
    image: 
    {
      uri: '/food/돈까스.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '우동', 
    style: { backgroundColor: 'chocolate' },
    image: 
    {
      uri: '/food/우동.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '소바', 
    style: { backgroundColor: 'cornsilk' },
    image: 
    {
      uri: '/food/소바.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '규동', 
    style: { backgroundColor: 'darkgreen' },
    image: 
    {
      uri: '/food/규동.png', 
      sizeMultiplier:img_size
    } 
  },
];

const chineseData = [
  { 
    option: '짜장면', 
    style: { backgroundColor: 'lime' },
    image: 
    {
      uri: '/food/짜장면.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '짬뽕', 
    style: { backgroundColor: 'darkorchid' },
    image: 
    {
      uri: '/food/짬뽕.png', 
      sizeMultiplier:img_size
    } 
  },
  { 
    option: '마라탕', 
    style: { backgroundColor: 'ghostwhite' },
    image: 
    {
      uri: '/food/마라탕.png', 
      sizeMultiplier:img_size
    } 
  },
];

const westernData = [
    { 
      option: '샐러드', 
      style: { backgroundColor: 'yellow' }, 
      image: 
      {
        uri: '/food/salad.png', 
        sizeMultiplier:img_size
      } 
    },
    { 
      option: '치킨', 
      style: { backgroundColor: 'red' },
      image: 
      {
        uri: '/food/치킨.png', 
        sizeMultiplier:img_size
      } 
    },
    { 
      option: '피자', 
      style: { backgroundColor: 'olive' },
      image: 
      {
        uri: '/food/피자.png', 
        sizeMultiplier:img_size
      } 
    },
  ]

// 2단계 컨텐츠
function RouletteContent(prop) {
    var rouletteData = []; 
    var imgSize = 1;

    if (prop.selectedMenuType == 'korean') {
      rouletteData = koreanData;
      imgSize = 0.7;
    } else if (prop.selectedMenuType == 'japanese') {
      rouletteData = japaneseData;
      imgSize = 0.8;
    } else if (prop.selectedMenuType == 'chinese') {
      rouletteData = chineseData;
      imgSize = 0.8;
    } else if (prop.selectedMenuType == 'western') {
      rouletteData = westernData;
      imgSize = 0.8;
    } else {
      rouletteData.push(...koreanData);
      rouletteData.push(...japaneseData);
      rouletteData.push(...chineseData);
      rouletteData.push(...westernData);
      imgSize = 0.45;
    }

    rouletteData.forEach(item => {
      item.image.sizeMultiplier = imgSize;
    })

    useEffect(() => {
      // 컴포넌트 마운트 시 body의 display 스타일을 변경
      const originalDisplayStyle = document.body.style.display; // 원래의 display 스타일 저장
      document.body.style.display = 'flex'; // body의 display를 flex으로 설정
    });
  
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
  
    const handleSpinClick = () => {
      if (!mustSpin) {
        const newPrizeNumber = Math.floor(Math.random() * rouletteData.length);
        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);
      }
    }
  
    return (
      <>
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={rouletteData}
          outerBorderColor={'darkslategray'}
          outerBorderWidth={8}
          innerBorderColor={'darkgray'}
          radiusLineColor={'black'}
          radiusLineWidth={1.5}
          fontSize={20}
          innerRadius={11}
          textDistance={80}
          perpendicularText={true}
          spinDuration={0.5}

          onStopSpinning={() => {
            setMustSpin(false);
            if (window.confirm("'"+ rouletteData[prizeNumber].option + "'" + '메뉴를 고르시겠습니까?')) {
              prop.setMenuFunc(rouletteData[prizeNumber].option)
            }
          }}
        />
        
        <button onClick={handleSpinClick} className="btn btn-primary">SPIN</button>
      </>
    )
}

export default RouletteContent 