import React, { useEffect, useState } from 'react';
import './ResultContent.css';
import foodconImg from './foodcon.webp'; 

function ResultMap(props) {
  const [places, setPlaces] = useState([]);
  const [feedbacks, setFeedbacks] = useState({}); // 장소 ID별 리뷰 개수를 저장할 객체
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    // 컴포넌트 마운트 시 body의 display 스타일을 변경
    const originalDisplayStyle = document.body.style.display; // 원래의 display 스타일 저장

    if (isMobile == false) {
      document.body.style.display = 'block'; // body의 display를 block으로 설정
    }

    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_MAP_KEY}&autoload=false&libraries=services`;
    document.head.appendChild(script);

    script.onload = () => {
      window.kakao.maps.load(() => {
        const container = document.getElementById('map');
        const options = {
          center: new window.kakao.maps.LatLng(33.450701, 126.570667),
          level: 3,
        };
        const map = new window.kakao.maps.Map(container, options);

        const ps = new window.kakao.maps.services.Places();
        ps.keywordSearch(`${props.menu} ${props.addr}`, (data, status, _pagination) => {
          if (status === window.kakao.maps.services.Status.OK) {
            const bounds = new window.kakao.maps.LatLngBounds();
            data.forEach((place, index) => {
              console.log(place)
              const markerImageSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_number_blue.png';
              const imageSize = new window.kakao.maps.Size(36, 37);
              const imgOptions = {
                spriteSize: new window.kakao.maps.Size(36, 691),
                spriteOrigin: new window.kakao.maps.Point(0, index * 46 + 10),
                offset: new window.kakao.maps.Point(13, 37)
              };
              const markerImage = new window.kakao.maps.MarkerImage(markerImageSrc, imageSize, imgOptions);

              const marker = new window.kakao.maps.Marker({
                map: map,
                position: new window.kakao.maps.LatLng(place.y, place.x),
                image: markerImage,
              });

              // Create an InfoWindow
              let infoWindow = new window.kakao.maps.InfoWindow({
                content: `<div style="padding:5px;">${place.place_name}</div>` // Content of the InfoWindow
              });

              // Display InfoWindow on mouseover
              window.kakao.maps.event.addListener(marker, 'mouseover', () => {
                infoWindow.open(map, marker);
              });

              // Close InfoWindow on mouseout
              window.kakao.maps.event.addListener(marker, 'mouseout', () => {
                infoWindow.close();
              });

              bounds.extend(new window.kakao.maps.LatLng(place.y, place.x));
            });

            // 각 장소의 리뷰 개수를 가져와 상태에 저장하는 함수
            const loadReviewCounts = async () => {
              const newFeedback = {};
              
              for (const place of data) {
                const feedbackInfo = await getFeedbackInfo(place.id);
                newFeedback[place.id] = feedbackInfo;
              }

              setFeedbacks(newFeedback); // 상태 업데이트
            };

            if (data.length > 0) {
              loadReviewCounts();
            }

            setPlaces(data);
            map.setBounds(bounds);
          }
        });
      });
    };
    return () => {
      document.body.style.display = originalDisplayStyle; // 컴포넌트 언마운트 시 body 스타일 원래대로 복원
      document.head.removeChild(script);
      window.removeEventListener('resize', handleResize);
    }
  }, [props.menu, props.addr]);

  // 장소 ID를 이용해 카카오맵 API로부터 리뷰 개수를 가져오는 함수
  const getFeedbackInfo = async (placeId) => {
    const url = `https://${process.env.REACT_APP_BACKEND_URL}/api/scape/kakao-review?place-id=${placeId}`;
    console.log(url)
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok.');

      const data = await response.json();
      console.log(`Review Count for ${placeId}:`, data);

      return data.basicInfo.feedback;
    } catch (error) {
      console.error("Failed to fetch review count:", error);
      return null; // 에러가 발생했을 경우, null을 반환
    }
  };  

  const cafe24_foodcon_info = {
    "484336208": "BJ냉면",
    "1914328705": "오지상가츠",
    "1282958353": "밥스틸러김치찜 서울신림점",
    "1282958353": "고향한정식",
    "1038505327": "구서방불향네쭈꾸미",
    "328311280": "길동우동 보라매점",
    "11023760": "김명자굴국밥 보라매점",
    "26406822": "김사랑김밥",
    "16733267": "대궁",
    "1250937609": "대방칼국수 수제비",
    "2024133657": "마리크루아상",
    "16707203": "만나보라",
    "12070174": "미전",
    "914969137": "바우네순대국",
    "1460788952": "베러배러",
    "1639047386": "보라매칼국수",
    "25406298": "써브웨이 보라매점",
    "?": "소공동 뚝배기집 보라매점",
    "1062500149": "소반 보라매본점",
    "776664610": "순남시래기 보라매점", 
    "16992981": "윤희네분식",
    "16935791": "전주집",
    "13322538": "죠스떡볶이 보라매점",
    "23956872": "중국관",
    "29075634": "트러스트커피",
    "243694080": "포들 보라매점",
    "1674972841": "피자먹다 보라매점",
    "??": "해태철판볶음밥",
    "16651368": "허수아비돈까스",
    "314185452": "달콤바니베이크샵",
    "???": "커피앤김밥",
    "806164151": "그로비",
    "????": "정성보리밥",
    "19038133": "월드돈까스",
    "?????": "달렘",
    "1091480249": "타꼬몽",
    "16331803": "명희네분식",
    "????": "덮담 신림점",
    "13564503": "여의도수제비",
    "863483135": "빽다방 보라매타운점",
    "1947778432": "에그드랍 보라매점",
    "8565975": "파리바게뜨 보라매타운점",
    "1928206351": "강촌민물매운탕 2호점",
    "14721081": "궁중참순대",
    "172112877": "꼬리야",
    "530779412": "늘",
    "719980524": "서평면옥",
    "2062907746": "울엄마쌈밥",
    "15324223": "이레칼국수",
    "831905154": "한우촌",
    "??": "하루낙지",
    "??": "배밭골",
    "72434696": "집밥",
    "18248664": "한솥도시락 보라매삼성점",
    "8823723": "궁중 삼계탕",
    "2030422278": "더플롯커피",
    "1081186310": "본죽&비빔밥cafe 보라매점",
    "1524671476": "산쪼메 보라매타운점",
    "2131413684": "샐러디 보라매점",
    "972017893": "슬로우캘리 보라매공원점",
    "308745920": "육가온 보라매점",
    "2023214465": "투썸플레이스 보라매삼성쉐르빌점",
    "12503554": "포메인 보라매점",
    "2078180110": "샐그릿",
    "698533249": "류우텐",
    "1653784008": "컴온더그린",
    "499443329": "대박김치찌개",
    "1346313796": "스시진",
    "444682015": "진아김밥",
    "2008805684": "메리커피플레이스",
    "1241394221": "원조감자탕일미집 보라매점",
    "353632296": "동해해물찜",
    "10373174": "등촌샤브칼국수 보라매점",
    "8060014": "라이스&파스타",
    "138460322": "마라탕전문점",
    "16268837": "명가",
    "1279359567": "빚은 르뽀미에보라매점",
    "463767451": "사만다부대찌개",
    "12550369": "샤우",
    "195400921": "아비꼬",
    "1491625426": "애머이",
    "20889795": "원할머니보쌈족발 보라매점",
    "1289008144": "이태리로간고등어",
    "2029831756": "이화수전통육개장 서울보라매점",
    "?": "이화원",
    "1344793184": "백소정 보라매점",
    "295437642": "암파크",
    "394218089": "플러버 보라매공원점",
    "739812128": "조프로 당곡본점",
    "?": "아토키토",
    "1374891909": "구름산추어탕 보라매점",
    "448843857": "그김에 보라매점",
    "338969152": "남도묵은지맛집",
    "21235127": "대경정육점식당",
    "16045924": "대구한뽈때기",
    "1160345961": "더블플레이치킨 보라매점",
    "743450627": "란콰이펑누들 보라매공원점",
    "?": "릿커피",
    "144552010": "보쌈주는족발집 보라매점",
    "1342134707": "삼산회관 보라매공원점",
    "1112648795": "속초오징어어시장",
    "1349027923": "스시모도리",
    "1120247515": "텐동신텐",
    "9202811": "아궁이묵은지삼겹살",
    "15592340": "돈가네 옛날김치돼지찌개 보라매점",
    "768850911": "월화고기 보라매직영점",
    "1081765123": "육회바른연어 보라매점",
    "9410770": "장추추어탕 본점",
    "1128734402": "체크커피",
    "26364101": "태능참숯불갈비",
    "?": "티엔",
    "111188620": "푸른연어",
    "12601467": "한촌설렁탕 보라매점",
    "1587319317": "포코하노이",
    "474654663": "무안갯벌낙지",
    "1996185647": "배영만 당진아구동태찜탕 보라매본점",
    "708320215": "진상천 보라매점",
    "10521462": "신안산낙지",
    "16733741": "옛집"
  };

  const mapStyle = {
    zoom: isMobile ? 0.7 : 'normal', // 모바일 환경에서 zoom 조정
  };

  return (
    <div className="map_wrap" style={mapStyle}>
      <div id="map" style={{ width: '100%', height: '100%' }}></div>
      <div id="menu_wrap" className="bg_white">
        <ul id="placesList">
          {places.map((place, index) => (
            <li key={index} className="item">
              <div className={`markerbg marker_${index + 1}`}></div>
              <div className="info">
                <a href={place.place_url} target="_blank">
                  <h5>
                    {
                      place.id in cafe24_foodcon_info &&
                      <img src={foodconImg} alt="푸드콘" style={{ width: '24px', height: '24px'}} />
                    }
                    &nbsp; {place.place_name}
                  </h5>                
                </a>
                <span>- 리뷰 개수: {feedbacks[place.id]?.blogrvwcnt ?? '로딩 중...'}</span>
                <span>
                    {`,- 평점: ${
                        feedbacks[place.id]?.scorecnt
                            ? (feedbacks[place.id].scoresum / feedbacks[place.id].scorecnt).toFixed(1)
                            : '평점 정보 없음'
                    }`}
                </span>
                {place.road_address_name && (
                  <>
                    <span>{place.road_address_name}</span>
                    <span className="jibun gray">{place.address_name}</span>
                  </>
                )}
                {!place.road_address_name && <span>{place.address_name}</span>}
                <span className="tel">{place.phone}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ResultMap;
